import styled from "@emotion/styled";
import useFetchMetadata, {
  MetadataFetchType,
  MetadataFetchStatus,
} from "../../sharedUtilities/useFetchMetadata";
import { ProductClass, VizType } from "../Utils";
import {
  getGroupLevelFromGroupMetadatum,
  getLocationLevelFromStringLocationId,
} from "../../sharedUtilities/Utils";
import { GroupLevel, LocationLevel } from "../../graphql/types";
import { getTradeDirection } from "../Utils";
import { allProductsDatum } from "../../graphql/queries/getProductsMetadata";
import { UIView } from "../Utils";
import { worldGroupDatum } from "../../graphql/queries/getLocationsMetadata";
import { VisualizationTitleBase } from "../VizGrid";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { usePageQueryParams } from "../defaultSettings";
import { useDownload } from "../DownloadContext";

const IntraRegionTradeDisclaimer = styled.span`
  && {
    margin-left: 5px;
    font-size: 12px;
  }
`;

const TitleTooltip = styled.div`
  position: absolute;
  top: -200%;
  left: 0px;
  width: 100%;
  font-size: 0.7rem;
  text-transform: none;
  background-color: #ffffff;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  z-index: 100;
  box-shadow: 0px 2px 15px #ccc;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ffffff;
  }
`;

const TitleWithTooltipContainer = styled.span`
  position: relative;
  display: inline-block;
  cursor: help;
  max-width: 150px;
`;

const TitleShortNameContainer = styled.span`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  font-style: italic;
  vertical-align: text-top;
`;

const MAX_PRODUCT_NAME_LENGTH = 25;

const VisualizationTitleTooltip = ({ fullName }: any) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  if (fullName.length <= MAX_PRODUCT_NAME_LENGTH) {
    return <span>{fullName}</span>;
  }

  let fullNameTooltip;
  if (isHovered) {
    fullNameTooltip = <TitleTooltip>{fullName}</TitleTooltip>;
  } else {
    fullNameTooltip = null;
  }

  return (
    <TitleWithTooltipContainer
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <TitleShortNameContainer>{fullName}</TitleShortNameContainer>
      {fullNameTooltip}
    </TitleWithTooltipContainer>
  );
};

const determineIfHasIntraRegionTrade = ({
  exporter,
  exporterLocationLevel,
  foundMetadatumForExporter,
  importer,
  importerLocationLevel,
  foundMetadatumForImporter,
}: any) => {
  let hasIntraRegionTradeRelationship = false;

  if (
    importerLocationLevel === LocationLevel.Country ||
    exporterLocationLevel === LocationLevel.Country
  ) {
    if (
      importerLocationLevel === LocationLevel.Country &&
      exporterLocationLevel === LocationLevel.Group
    ) {
      let exporterMemberCountries = foundMetadatumForExporter.members;
      if (exporterMemberCountries.includes(importer)) {
        hasIntraRegionTradeRelationship = true;
      }
    } else if (
      exporterLocationLevel === LocationLevel.Country &&
      importerLocationLevel === LocationLevel.Group
    ) {
      let importerMemberCountries = foundMetadatumForImporter.members;
      if (importerMemberCountries.includes(exporter)) {
        hasIntraRegionTradeRelationship = true;
      }
    }
  } else if (
    exporterLocationLevel === LocationLevel.Group &&
    importerLocationLevel === LocationLevel.Group
  ) {
    let exporterGroupLevel = getGroupLevelFromGroupMetadatum(
      foundMetadatumForExporter,
    );
    let importerGroupLevel = getGroupLevelFromGroupMetadatum(
      foundMetadatumForImporter,
    );

    if (
      exporterGroupLevel === GroupLevel.Subregion &&
      importerGroupLevel === GroupLevel.Region
    ) {
      if (foundMetadatumForExporter.parentId === importer) {
        hasIntraRegionTradeRelationship = true;
      }
    } else if (
      exporterGroupLevel === GroupLevel.Region &&
      importerGroupLevel === GroupLevel.Subregion
    ) {
      if (foundMetadatumForImporter.parentId === exporter) {
        hasIntraRegionTradeRelationship = true;
      }
    } else if (exporter === importer) {
      hasIntraRegionTradeRelationship = true;
    }
  }

  return hasIntraRegionTradeRelationship;
};

const generateProductSpaceTitle = ({ exporterName, year }: any) => {
  return `${exporterName} in the Product Space, ${year}`;
};

const generateFeasibilityTitle = ({ exporterName, year }: any) => {
  return `Growth Opportunities for ${exporterName}, ${year}`;
};

const generateMarketShareTitle = ({
  exporterName,
  startYear,
  endYear,
}: any) => {
  if (exporterName) {
    return `${exporterName}'s global market share, ${startYear} – ${endYear}`;
  }
  return "";
};

const generateMarketsOverTimeTitle = ({
  isWorldExporter,
  isWorldImporter,
  exporter,
  importer,
  exporterName,
  importerName,
  productNameString,
  startYear,
  endYear,
}: any) => {
  if (isWorldExporter && importer === "") {
    return `Who exported ${productNameString}, ${startYear} – ${endYear}?`;
  } else if (isWorldImporter && exporter === "") {
    return `Who imported ${productNameString}, ${startYear} – ${endYear}?`;
  } else if (isWorldExporter) {
    return `Who exported ${productNameString} to ${importerName}, ${startYear} – ${endYear}?`;
  } else if (isWorldImporter) {
    return `Who imported ${productNameString} from ${exporterName}, ${startYear} – ${endYear}?`;
  }
  return "";
};

const generateMarketsTreeTitle = ({
  isWorldExporter,
  isWorldImporter,
  importer,
  importerName,
  exporter,
  exporterName,
  productNameString,
  year,
}: any) => {
  if (isWorldExporter && importer === "") {
    return `Who exported {{${productNameString}}} in ${year}?`;
  } else if (isWorldImporter && exporter === "") {
    return `Who imported {{${productNameString}}} in ${year}?`;
  } else if (isWorldExporter && importerName) {
    return `Where did ${importerName} import {{${productNameString}}} from in ${year}?`;
  } else if (isWorldImporter && exporterName) {
    return `Where did ${exporterName} export {{${productNameString}}} to in ${year}?`;
  }
  return "";
};

const generateMarketsDefaultTitle = ({
  isWorldExporter,
  isWorldImporter,
  exporter,
  exporterName,
  importerName,
  productNameString,
  year,
}: any) => {
  if (isWorldExporter || exporter === "") {
    if (!isWorldImporter && importerName && productNameString) {
      return `Where did ${importerName} import ${productNameString} from in ${year}?`;
    } else if (productNameString) {
      return `Who exported ${productNameString} in ${year}?`;
    }
  } else if (isWorldImporter && productNameString) {
    if (exporterName && productNameString) {
      return `Where did ${exporterName} export ${productNameString} to in ${year}?`;
    } else if (productNameString) {
      return `Who imported ${productNameString} in ${year}?`;
    }
  } else if (exporterName && productNameString) {
    return `Where did ${exporterName} export ${productNameString} to in ${year}?`;
  }
  return "";
};

const generateProductsTitle = ({
  currentVizType,
  exporter,
  importer,
  exporterName,
  importerName,
  startYear,
  endYear,
  year,
}: any) => {
  if (currentVizType === VizType.OverTime && startYear && endYear) {
    if (importer === worldGroupDatum.groupId && exporterName) {
      return `What did ${exporterName} export, ${startYear} – ${endYear}?`;
    } else if (exporter === worldGroupDatum.groupId && importerName) {
      return `What did ${importerName} import, ${startYear} – ${endYear}?`;
    } else if (
      exporter !== worldGroupDatum.groupId &&
      importer !== worldGroupDatum.groupId &&
      exporterName &&
      importerName
    ) {
      return `What did ${exporterName} export to ${importerName}, ${startYear} – ${endYear}?`;
    }
  } else {
    if (importer === worldGroupDatum.groupId && exporterName) {
      return `What did ${exporterName} export in ${year}?`;
    } else if (exporter === worldGroupDatum.groupId && importerName) {
      return `What did ${importerName} import in ${year}?`;
    } else if (
      exporter !== worldGroupDatum.groupId &&
      importer !== worldGroupDatum.groupId &&
      exporterName &&
      importerName
    ) {
      return `What did ${exporterName} export to ${importerName} in ${year}?`;
    }
  }
  return "";
};

const VisualizationTitleGenerator = ({ queryParams }: any) => {
  const { titleRef } = useDownload();
  const [
    {
      view,
      exporter,
      importer,
      year,
      product,
      productClass,
      startYear,
      endYear,
    },
  ] = usePageQueryParams();

  const location = useLocation();
  const currentVizType = location.pathname.split("/")[2] as any;

  const { metadataStatus: locationMetadataStatus, metadata: locationMetadata } =
    useFetchMetadata({ metadataFetchType: MetadataFetchType.Location });
  let useProductMetadataFetchType: MetadataFetchType | undefined;
  if (productClass === ProductClass.HS92Products) {
    useProductMetadataFetchType = MetadataFetchType.ProductsHs92;
  } else if (productClass === ProductClass.HS12Products) {
    useProductMetadataFetchType = MetadataFetchType.ProductsHs12;
  } else if (productClass === ProductClass.SITCProducts) {
    useProductMetadataFetchType = MetadataFetchType.ProductsSitc;
  } else {
    // This will never run
    useProductMetadataFetchType = MetadataFetchType.ProductsHs92;
  }

  const { metadataStatus: productMetadataStatus, metadata: productMetadata } =
    useFetchMetadata({ metadataFetchType: useProductMetadataFetchType });
  const exporterLocationLevel = getLocationLevelFromStringLocationId(exporter);
  const importerLocationLevel = getLocationLevelFromStringLocationId(importer);

  let exporterName: string | undefined = undefined;
  let importerName: string | undefined = undefined;
  let productName: JSX.Element | undefined = undefined;
  let productNameString: string | undefined = undefined;
  let hasIntraRegionTradeRelationship: boolean = false;

  if (locationMetadataStatus === MetadataFetchStatus.Success) {
    const { countries, regions, subregions } = locationMetadata;
    const metadataToSearch = [...countries, ...regions, ...subregions];
    const foundMetadatumForExporter = metadataToSearch.find((location: any) => {
      if (exporterLocationLevel === LocationLevel.Country) {
        return location.countryId === exporter;
      } else if (exporterLocationLevel === LocationLevel.Group) {
        return location.groupId === exporter;
      }
    });

    if (foundMetadatumForExporter) {
      if (exporterLocationLevel === LocationLevel.Country) {
        const prefix = foundMetadatumForExporter.thePrefix ? "the " : "";
        exporterName = prefix + foundMetadatumForExporter.nameShortEn;
      } else if (exporterLocationLevel === LocationLevel.Group) {
        exporterName = foundMetadatumForExporter.groupName;
      }
    }

    const foundMetadatumForImporter = metadataToSearch.find((location: any) => {
      if (importerLocationLevel === LocationLevel.Country) {
        return location.countryId === importer;
      } else if (importerLocationLevel === LocationLevel.Group) {
        return location.groupId === importer;
      }
    });

    if (foundMetadatumForImporter) {
      if (importerLocationLevel === LocationLevel.Country) {
        const prefix = foundMetadatumForImporter.thePrefix ? "the " : "";
        importerName = prefix + foundMetadatumForImporter.nameShortEn;
      } else if (importerLocationLevel === LocationLevel.Group) {
        importerName = foundMetadatumForImporter.groupName;
      }
    }

    if (foundMetadatumForExporter && foundMetadatumForImporter) {
      hasIntraRegionTradeRelationship = determineIfHasIntraRegionTrade({
        exporter,
        exporterLocationLevel,
        foundMetadatumForExporter,
        importer,
        importerLocationLevel,
        foundMetadatumForImporter,
      });
    }
  }

  const isWorldExporter = exporter === worldGroupDatum.groupId;
  const isWorldImporter = importer === worldGroupDatum.groupId;

  if (isWorldExporter) {
    exporterName = "the " + worldGroupDatum.groupName;
  }

  if (isWorldImporter) {
    importerName = "the " + worldGroupDatum.groupName;
  }

  if (product === allProductsDatum.productId) {
    productNameString = allProductsDatum.nameShortEn;
    productName = <VisualizationTitleTooltip fullName={productNameString} />;
  } else {
    if (productMetadataStatus === MetadataFetchStatus.Success) {
      let metadataToSearch;
      if (productClass === ProductClass.HS92Products) {
        const { section, twoDigit, fourDigit, sixDigit } = productMetadata;
        metadataToSearch = [...section, ...twoDigit, ...fourDigit, ...sixDigit];
      } else if (productClass === ProductClass.SITCProducts) {
        const { section, twoDigit, fourDigit } = productMetadata;
        metadataToSearch = [...section, ...twoDigit, ...fourDigit];
      }

      const foundMetadatumForProduct =
        metadataToSearch &&
        metadataToSearch.find(
          (productMetadatum: any) => productMetadatum.productId === product,
        );
      if (foundMetadatumForProduct) {
        productNameString = foundMetadatumForProduct.nameShortEn;
        productName = (
          <VisualizationTitleTooltip fullName={productNameString} />
        );
      }
    }
  }

  const tradeDirection = getTradeDirection({
    exporter,
    importer,
    locationForAPI: exporter,
    partnerForAPI: importer,
  });

  let completedTitleParts: (string | JSX.Element)[] = [];
  let completedTitleString: string = "";

  const addTitlePart = (text: string, isProduct: boolean = false) => {
    if (isProduct) {
      completedTitleParts.push(
        <VisualizationTitleTooltip
          key={completedTitleParts.length}
          fullName={text}
        />,
      );
      completedTitleString += text;
    } else {
      completedTitleParts.push(text);
      completedTitleString += text;
    }
  };

  let title = "";

  if (currentVizType === VizType.ProductSpace) {
    title = generateProductSpaceTitle({ exporterName, year });
  } else if (currentVizType === VizType.Feasibility) {
    title = generateFeasibilityTitle({ exporterName, year });
  } else if (currentVizType === VizType.MarketShare) {
    title = generateMarketShareTitle({ exporterName, startYear, endYear });
  } else if (view === UIView.Markets) {
    if (currentVizType === VizType.OverTime && startYear && endYear) {
      title = generateMarketsOverTimeTitle({
        isWorldExporter,
        isWorldImporter,
        exporter,
        importer,
        exporterName,
        importerName,
        productNameString,
        startYear,
        endYear,
      });
    } else if (currentVizType === VizType.Tree) {
      title = generateMarketsTreeTitle({
        isWorldExporter,
        isWorldImporter,
        importer,
        importerName,
        exporterName,
        productNameString,
        year,
        exporter,
      });
    } else {
      title = generateMarketsDefaultTitle({
        isWorldExporter,
        isWorldImporter,
        exporter,
        exporterName,
        importerName,
        productNameString,
        year,
      });
    }
  } else if (view === UIView.Products) {
    title = generateProductsTitle({
      currentVizType,
      exporter,
      importer,
      exporterName,
      importerName,
      startYear,
      endYear,
      year,
    });
  }

  if (title) {
    const titleParts = title.split(/({{.*?}})/);
    titleParts.forEach((part) => {
      if (part.startsWith("{{") && part.endsWith("}}")) {
        const productName = part.slice(2, -2);
        addTitlePart(productName, true);
      } else {
        addTitlePart(part);
      }
    });
  }

  const completedTitle = (
    <>
      {completedTitleParts}
      {hasIntraRegionTradeRelationship && (
        <IntraRegionTradeDisclaimer>
          (Includes intra-region trade)
        </IntraRegionTradeDisclaimer>
      )}
    </>
  );

  useEffect(() => {
    if (completedTitleString) {
      titleRef.current = completedTitleString;
    }
  }, [completedTitleString, titleRef]);

  if (completedTitleParts.length > 0) {
    return <VisualizationTitleBase>{completedTitle}</VisualizationTitleBase>;
  } else {
    return null;
  }
};

export default VisualizationTitleGenerator;
