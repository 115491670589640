import SelectBox from "./SelectBoxPrimitive";
import SettingsDropdownItem from "./SettingsDropdownItem";
import {
  ColorBy,
  ProductClass,
  ProductLevel,
  ServicesClass,
  TradeFlow,
  VizType,
  getProductClassYearRange,
} from "../../../visualization/Utils";
import { memo } from "react";

import { LocationDetailLevel } from "../../../visualization/Utils";
import {
  OverTimeLayoutOption,
  OverTimeOrderingOption,
} from "../../../visualization/charts/overtime/Utils";
import {
  HideWorldTradeOption,
  OpportunitySizeOption,
} from "../../../visualization/charts/growth/utils";
import { useLocation } from "react-router-dom";

import { usePageQueryParams } from "../../../visualization/defaultSettings";
import { allProductsDatum } from "../../../graphql/queries/getProductsMetadata";
import { worldGroupDatum } from "../../../graphql/queries/getLocationsMetadata";
import { latestProductYear } from "../../../graphql/Utils";

export enum YearSelectionRole {
  StartYear = "StartYear",
  EndYear = "EndYear",
  FixedYear = "FixedYear",
}

export enum SettingOption {
  ProductLevel = "ProductLevel",
  LocationDetailLevel = "LocationDetailLevel",
  ProductClass = "ProductClass",
  ServicesClass = "ServicesClass",
  TradeFlow = "TradeFlow",
  ColorBy = "ColorBy",
  OverTimeOrdering = "OverTimeOrdering",
  OverTimeLayout = "OverTimeLayout",
  Sizing = "Sizing",
  HideExports = "HideExports",
}

export interface SettingSelectBoxInput {
  setting: SettingOption;
}

const SettingsDropdown = ({ setting }: SettingSelectBoxInput) => {
  const location = useLocation();
  const currentVizType = location.pathname.split("/")[2] as any;
  const [
    {
      sizing,
      hideExports,
      productClass: currentProductClass,
      productLevel: currentProductLevel,
      locationLevel: currentLocationDetailLevel,
      colorBy: currentColorBySelection,
      tradeFlow: currentTradeFlow,
      ordering: currentOverTimeOrderingOption,
      layout: currentOverTimeLayout,
      startYear: currentStartYear,
      endYear: currentEndYear,
      year: currentYear,
      servicesClass: currentServicesClass,
      exporter: currentExporter,
      importer: currentImporter,
    },
    setQuery,
  ] = usePageQueryParams();

  let items: any = [];
  let dispatchAction;
  let matchSelectedItem;

  const allProductLevelItems = [
    { label: "Section (1 digit)", value: ProductLevel.ProductSection },
    { label: "2 digit", value: ProductLevel.Product2digit },
    { label: "4 digit", value: ProductLevel.Product4digit },
    { label: "6 digit", value: ProductLevel.Product6digit },
  ];

  if (setting === SettingOption.ProductLevel) {
    const showTable = location.pathname.includes("feasibility/table");

    if (currentProductClass === ProductClass.HS92Products) {
      items = allProductLevelItems.map((item) => {
        let disabled = false;

        if (currentVizType === VizType.Feasibility) {
          if (showTable) {
            disabled = item.value !== ProductLevel.Product4digit;
          } else {
            disabled = item.value === ProductLevel.Product6digit;
          }
        } else if (currentVizType === VizType.ProductSpace) {
          disabled = item.value !== ProductLevel.Product4digit;
        }

        return {
          ...item,
          disabled,
        };
      });
    } else if (currentProductClass === ProductClass.HS12Products) {
      items = allProductLevelItems;
    } else if (currentProductClass === ProductClass.SITCProducts) {
      items = allProductLevelItems.map((item) => ({
        ...item,
        disabled: item.value === ProductLevel.Product6digit,
      }));
    }

    dispatchAction = ({ value }: { value: ProductLevel }) => {
      const newSearch = {};
      if (
        value !== ProductLevel.Product4digit &&
        currentColorBySelection === ColorBy.Complexity
      ) {
        newSearch["colorBy"] = undefined;
      }
      newSearch["productLevel"] = value;
      setQuery(newSearch);
    };

    matchSelectedItem = items.find(
      (item: any) => item.value == currentProductLevel,
    );
  } else if (setting === SettingOption.ProductClass) {
    items = [
      {
        label: (
          <>
            HS 1992{" "}
            <span style={{ fontSize: "10px" }}>(1995-{latestProductYear})</span>
          </>
        ),
        value: ProductClass.HS92Products,
      },
      {
        label: (
          <>
            HS 2012 <span style={{ fontSize: "10px" }}>(2019-2021)</span>
          </>
        ),
        value: ProductClass.HS12Products,
      },
      {
        label: (
          <>
            SITC rev.2 <span style={{ fontSize: "10px" }}>(1962-2021)</span>
          </>
        ),
        value: ProductClass.SITCProducts,
        disabled: currentProductLevel === ProductLevel.Product6digit,
      },
    ];

    if (currentVizType === VizType.ProductSpace) {
      items = items.map((item) => ({
        ...item,
        disabled: item.value !== ProductClass.HS92Products,
      }));
    }

    dispatchAction = ({ value }: { value: ProductClass }) => {
      if (currentProductClass !== value) {
        const newSearch = {} as any;
        const defaultYearRangeForSelectedProductClass =
          getProductClassYearRange({
            productClass: value as any,
          });

        let { startYear: defaultStartYear, endYear: defaultEndYear } =
          defaultYearRangeForSelectedProductClass;
        if (
          currentStartYear === undefined ||
          currentEndYear === undefined ||
          (value === ProductClass.HS92Products &&
            (currentStartYear < defaultStartYear ||
              currentEndYear > defaultEndYear)) ||
          (value === ProductClass.SITCProducts &&
            (currentStartYear > defaultStartYear ||
              currentEndYear > defaultEndYear)) ||
          value === ProductClass.HS12Products
        ) {
          newSearch.startYear =
            defaultYearRangeForSelectedProductClass.startYear;
          newSearch.endYear = defaultYearRangeForSelectedProductClass.endYear;

          if (currentYear < defaultStartYear) {
            newSearch.year = defaultStartYear;
          } else if (currentYear > defaultEndYear) {
            newSearch.year = defaultEndYear;
          }
        }
        newSearch.productClass = value;
        newSearch.product = allProductsDatum.productId;
        setQuery(newSearch);
      }
    };

    matchSelectedItem = items.find(
      (item: any) => item.value == currentProductClass,
    );
  } else if (setting === SettingOption.ServicesClass) {
    items = [
      { label: "Bilateral", value: ServicesClass.bilateral },
      { label: "Unilateral", value: ServicesClass.unilateral },
    ];

    dispatchAction = ({ value }: { value: ServicesClass }) => {
      setQuery({ servicesClass: value });
    };

    matchSelectedItem = items.find(
      (item: any) => item.value === currentServicesClass,
    );
  } else if (setting === SettingOption.LocationDetailLevel) {
    items = [
      { label: "Region", value: LocationDetailLevel.region },
      { label: "Subregion", value: LocationDetailLevel.subregion },
      { label: "Country", value: LocationDetailLevel.country },
    ];

    dispatchAction = ({ value }: { value: LocationDetailLevel }) => {
      setQuery({ locationLevel: value });
    };

    matchSelectedItem = items.find(
      (item: any) => item.value == currentLocationDetailLevel,
    );
  } else if (setting === SettingOption.ColorBy) {
    items = [
      { label: "Sector", value: ColorBy.Sector },
      { label: "Product Complexity", value: ColorBy.Complexity },
    ];
    if (currentVizType === VizType.ProductSpace) {
      items.push({
        label: "Cluster",
        value: ColorBy.Cluster,
      });
      items.push({
        label: "Green Enablers",
        value: ColorBy.Green,
      });
    }

    dispatchAction = ({ value }: { value: ColorBy }) => {
      setQuery({ colorBy: value });
    };

    matchSelectedItem = items.find(
      (item: any) => item.value == currentColorBySelection,
    );
  } else if (setting === SettingOption.TradeFlow) {
    const isWorldExporter =
      currentExporter === worldGroupDatum.groupId || currentExporter === "";
    const isWorldImporter =
      currentImporter === worldGroupDatum.groupId || currentImporter === "";
    const isWorldToWorld = isWorldExporter && isWorldImporter;

    items = [
      { label: "Gross", value: TradeFlow.Gross },
      {
        label: "Net",
        value: TradeFlow.Net,
        disabled: isWorldToWorld,
      },
    ];

    dispatchAction = ({ value }: { value: TradeFlow }) => {
      setQuery({ tradeFlow: value });
    };

    matchSelectedItem = items.find(
      (item: any) => item.value == currentTradeFlow,
    );
  } else if (setting === SettingOption.OverTimeOrdering) {
    items = [
      { label: "Community", value: OverTimeOrderingOption.Community },
      { label: "Totals", value: OverTimeOrderingOption.Totals },
    ];

    dispatchAction = ({ value }: { value: OverTimeOrderingOption }) => {
      setQuery({ ordering: value });
    };

    matchSelectedItem = items.find(
      (item: any) => item.value == currentOverTimeOrderingOption,
    );
  } else if (setting === SettingOption.OverTimeLayout) {
    items = [
      { label: "Value", value: OverTimeLayoutOption.Value },
      { label: "Share", value: OverTimeLayoutOption.Share },
    ];

    dispatchAction = ({ value }: { value: OverTimeLayoutOption }) => {
      setQuery({ layout: value });
    };

    matchSelectedItem = items.find(
      (item: any) => item.value == currentOverTimeLayout,
    );
  } else if (setting === SettingOption.Sizing) {
    if (currentVizType === VizType.Feasibility) {
      items = [
        { label: "Uniform", value: OpportunitySizeOption.None },
        { label: "World Trade", value: OpportunitySizeOption.WorldTrade },
      ];
    } else {
      items = [
        { label: "Uniform", value: OpportunitySizeOption.None },
        { label: "World Trade", value: OpportunitySizeOption.WorldTrade },
        { label: "Country Trade", value: OpportunitySizeOption.CountryTrade },
      ];
    }
    matchSelectedItem = items.find((item: any) => item.value === sizing);
    dispatchAction = ({ value }: { value: OpportunitySizeOption }) => {
      setQuery({ sizing: value });
    };
  } else if (setting === SettingOption.HideExports) {
    items = [
      { label: "On", value: HideWorldTradeOption.On },
      { label: "Off", value: HideWorldTradeOption.Off },
    ];
    matchSelectedItem = items.find((item: any) => item.value === hideExports);
    dispatchAction = ({ value }: { value: HideWorldTradeOption }) => {
      setQuery({ hideExports: value });
    };
  }

  return (
    <>
      <SelectBox
        settingOption={setting}
        items={items}
        hashFunction={(item: any) => item.label}
        ItemRenderComponent={SettingsDropdownItem}
        onChangeEvent={dispatchAction}
        matchSelectedItem={matchSelectedItem}
      />
    </>
  );
};

export default memo(SettingsDropdown);
