import { gql } from "@apollo/client";

const query = gql`
  query GetCountries {
    countries: locationCountry {
      countryId
      locationLevel
      iso3Code
      nameEn
      nameShortEn
      legacyCountryId
      inCp
      inMv
      isTrusted
      reportedServ
      reportedServRecent
      thePrefix
    }
  }
`;

export default query;
