import { groups, sum } from "d3";
import { getLocationQualifiers, LocationDetailLevel } from "../../Utils";
import { getLocationLevelFromStringLocationId } from "../../../sharedUtilities/Utils";
import { LocationLevel } from "../../../graphql/types";
import { getTradeValueForWorldSelection } from "../../Utils";

const transformLocations = ({
  data,
  year,
  location,
  currentLocationDetailLevel,
  regions,
  subregions,
  countries,
  exporter,
  importer,
}: any) => {
  const { exporterIsWorld, importerIsWorld } = getLocationQualifiers({
    exporter,
    importer,
  });

  // Use this when mapping the data
  const getValue = (exportValue: number, importValue: number) => {
    return getTradeValueForWorldSelection({
      exporterIsWorld,
      importerIsWorld,
      exporter,
      importer,
      exportValue,
      importValue,
    });
  };

  let topLevelParents = new Set(regions.map((region: any) => region.groupId));

  let locationLevel = getLocationLevelFromStringLocationId(location);

  if (data.length === 0) {
    return undefined;
  }

  if (location) {
    if (locationLevel === LocationLevel.Country) {
      const groupedFilteredData = groups(
        data,
        (d: any) => d.partnerCountryId,
      ).map((d: any) => {
        const partnerCountryId = d[0];
        const partnerLevel = d[1][0].partnerLevel;
        const sumExportValue = sum(d[1], (v: any) => v.exportValue);
        const sumImportValue = sum(d[1], (v: any) => v.importValue);
        return {
          id: location,
          locationLevel: "country",
          partnerCountryId,
          partnerLevel,
          year: year,
          exportValue: sumExportValue,
          importValue: sumImportValue,
          productsData: d[1],
          value: getValue(sumExportValue, sumImportValue),
        };
      });

      const dataWithParents = groupedFilteredData.map((d: any) => {
        const partnerCountryId = d.partnerCountryId;
        let findMatchingMetadata = regions.find((region: any) =>
          region.members.includes(partnerCountryId),
        );
        let topLevelParent = undefined;
        let nameEn = undefined;
        let hybrid_level_1;
        let hybrid_level_2;
        let countryIso3Code = undefined;
        if (findMatchingMetadata) {
          topLevelParent = findMatchingMetadata.groupId;
          hybrid_level_1 = {
            id: findMatchingMetadata.groupId,
            nameEn: findMatchingMetadata.groupName,
          };
        }

        let findMatchingSubregion = subregions.find((subregion: any) =>
          subregion.members.includes(partnerCountryId),
        );
        if (findMatchingSubregion) {
          hybrid_level_2 = {
            id: findMatchingSubregion.groupId,
            nameEn: findMatchingSubregion.groupName,
          };
        }

        let findMatchingCountryMetadata = countries.find(
          (country: any) => country.countryId === partnerCountryId,
        );
        if (findMatchingCountryMetadata) {
          nameEn = findMatchingCountryMetadata.nameShortEn;
          countryIso3Code = findMatchingCountryMetadata.iso3Code;
        }

        return {
          ...d,
          id: d.partnerCountryId,
          topLevelParent,
          nameEn,
          hybrid_level_1,
          hybrid_level_2,
          countryIso3Code,
        };
      });

      let groupedByLocationDetailLevel;
      if (currentLocationDetailLevel === LocationDetailLevel.country) {
        groupedByLocationDetailLevel = dataWithParents;
      } else if (currentLocationDetailLevel === LocationDetailLevel.subregion) {
        groupedByLocationDetailLevel = groups(
          dataWithParents,
          (d: any) => d.hybrid_level_2.id,
        ).map((d: any) => {
          const groupId = d[0];
          const sumExportValue = sum(d[1], (v: any) => v.exportValue);
          const sumImportValue = sum(d[1], (v: any) => v.importValue);
          return {
            id: groupId,
            countryId: location,
            locationLevel: "country",
            partnerLevel: LocationLevel.Group,
            groupName: d[1][0].hybrid_level_2.nameEn,
            year: year,
            exportValue: sumExportValue,
            importValue: sumImportValue,
            productsData: d[1],
            topLevelParent: d[1][0].topLevelParent,
            hybrid_level_1: d[1][0].hybrid_level_1,
            hybrid_level_2: d[1][0].hybrid_level_2,
            value: getValue(sumExportValue, sumImportValue),
          };
        });
      } else if (currentLocationDetailLevel === LocationDetailLevel.region) {
        groupedByLocationDetailLevel = groups(
          dataWithParents,
          (d: any) => d.hybrid_level_1.id,
        ).map((d: any) => {
          const groupId = d[0];
          const sumExportValue = sum(d[1], (v: any) => v.exportValue);
          const sumImportValue = sum(d[1], (v: any) => v.importValue);
          return {
            id: groupId,
            countryId: location,
            locationLevel: "country",
            partnerLevel: LocationLevel.Group,
            groupName: d[1][0].hybrid_level_1.nameEn,
            year: year,
            exportValue: sumExportValue,
            importValue: sumImportValue,
            productsData: d[1],
            hybrid_level_1: d[1][0].hybrid_level_1,
            hybrid_level_2: d[1][0].hybrid_level_2,
            topLevelParent: "root",
            value: getValue(sumExportValue, sumImportValue),
          };
        });
      } else {
        // This will never execute
        groupedByLocationDetailLevel = dataWithParents;
      }

      let topLevelParentsObjects = [...topLevelParents.values()].map((t) => ({
        id: t,
        topLevelParent: "root",
      }));

      const rootObject = { id: "root", topLevelParent: undefined };
      return [
        rootObject,
        ...topLevelParentsObjects,
        ...groupedByLocationDetailLevel,
      ];
    } else if (locationLevel === LocationLevel.Group) {
      const groupedFilteredData = groups(
        data,
        (d: any) => d.partnerCountryId,
      ).map((d: any) => {
        const partnerCountryId = d[0];
        const sumExportValue = sum(d[1], (v: any) => v.exportValue);
        const sumImportValue = sum(d[1], (v: any) => v.importValue);
        return {
          groupId: location,
          locationLevel: "country",
          partnerCountryId,
          year: year,
          exportValue: sumExportValue,
          importValue: sumImportValue,
          productsData: d[1],
          value: getValue(sumExportValue, sumImportValue),
        };
      });

      const dataWithParents = groupedFilteredData.map((d: any) => {
        const partnerCountryId = d.partnerCountryId;
        let findMatchingMetadata = regions.find((region: any) =>
          region.members.includes(partnerCountryId),
        );
        let topLevelParent = undefined;
        let nameEn = undefined;
        let hybrid_level_1;
        let hybrid_level_2;
        let countryIso3Code = undefined;
        if (findMatchingMetadata) {
          topLevelParent = findMatchingMetadata.groupId;
          hybrid_level_1 = {
            id: findMatchingMetadata.groupId,
            nameEn: findMatchingMetadata.groupName,
          };
        }

        let findMatchingSubregion = subregions.find((subregion: any) =>
          subregion.members.includes(partnerCountryId),
        );
        if (findMatchingSubregion) {
          hybrid_level_2 = {
            id: findMatchingSubregion.groupId,
            nameEn: findMatchingSubregion.groupName,
          };
        }

        let findMatchingCountryMetadata = countries.find(
          (country: any) => country.countryId === partnerCountryId,
        );
        if (findMatchingCountryMetadata) {
          nameEn = findMatchingCountryMetadata.nameShortEn;
          countryIso3Code = findMatchingCountryMetadata.iso3Code;
        }

        return {
          ...d,
          id: partnerCountryId,
          topLevelParent,
          nameEn,
          hybrid_level_1,
          hybrid_level_2,
          countryIso3Code,
        };
      });

      let groupedByLocationDetailLevel;
      if (currentLocationDetailLevel === LocationDetailLevel.country) {
        groupedByLocationDetailLevel = dataWithParents;
      } else if (currentLocationDetailLevel === LocationDetailLevel.subregion) {
        groupedByLocationDetailLevel = groups(
          dataWithParents,
          (d: any) => d.hybrid_level_2.id,
        ).map((d: any) => {
          const groupId = d[0];
          const partnerLevel = d[1][0].partnerLevel;
          const sumExportValue = sum(d[1], (v: any) => v.exportValue);
          const sumImportValue = sum(d[1], (v: any) => v.importValue);
          return {
            id: groupId,
            groupName: d[1][0].hybrid_level_2.nameEn,
            partnerLevel,
            year: year,
            exportValue: sumExportValue,
            importValue: sumImportValue,
            productsData: d[1],
            topLevelParent: d[1][0].topLevelParent,
            hybrid_level_1: d[1][0].hybrid_level_1,
            hybrid_level_2: d[1][0].hybrid_level_2,
            value: getValue(sumExportValue, sumImportValue),
          };
        });
      } else if (currentLocationDetailLevel === LocationDetailLevel.region) {
        groupedByLocationDetailLevel = groups(
          dataWithParents,
          (d: any) => d.hybrid_level_1.id,
        ).map((d: any) => {
          const groupId = d[0];
          const partnerLevel = d[1][0].partnerLevel;
          const sumExportValue = sum(d[1], (v: any) => v.exportValue);
          const sumImportValue = sum(d[1], (v: any) => v.importValue);
          return {
            id: groupId,
            groupName: d[1][0].hybrid_level_1.nameEn,
            partnerLevel,
            year: year,
            exportValue: sumExportValue,
            importValue: sumImportValue,
            productsData: d[1],
            topLevelParent: "root",
            hybrid_level_1: d[1][0].hybrid_level_1,
            hybrid_level_2: d[1][0].hybrid_level_2,
            value: getValue(sumExportValue, sumImportValue),
          };
        });
      } else {
        // This will never execute
        groupedByLocationDetailLevel = dataWithParents;
      }

      let topLevelParentsObjects = [...topLevelParents.values()].map((t) => ({
        id: t,
        topLevelParent: "root",
      }));

      const rootObject = { id: "root", topLevelParent: undefined };
      let useTopLevelParentsObjects: any[] =
        currentLocationDetailLevel === LocationDetailLevel.region
          ? []
          : topLevelParentsObjects;
      return [
        rootObject,
        ...useTopLevelParentsObjects,
        ...groupedByLocationDetailLevel,
      ];
    }
  } else if (!location) {
    const dataWithParents = data.map((d: any) => {
      const countryId = d.countryId;
      let findMatchingMetadata = regions.find((region: any) =>
        region.members.includes(countryId),
      );
      let topLevelParent = undefined;
      let nameEn = undefined;
      let hybrid_level_1;
      let hybrid_level_2;
      if (findMatchingMetadata) {
        topLevelParent = findMatchingMetadata.groupId;
        hybrid_level_1 = {
          id: findMatchingMetadata.groupId,
          nameEn: findMatchingMetadata.groupName,
        };
      } else {
        topLevelParent = "undisclosed";
        hybrid_level_1 = { id: "undisclosed", nameEn: "undisclosed" };
      }

      let findMatchingSubregion = subregions.find((subregion: any) =>
        subregion.members.includes(countryId),
      );
      if (findMatchingSubregion) {
        hybrid_level_2 = {
          id: findMatchingSubregion.groupId,
          nameEn: findMatchingSubregion.groupName,
        };
      } else {
        hybrid_level_2 = { id: "undisclosed", nameEn: "undisclosed" };
      }

      let findMatchingCountryMetadata = countries.find(
        (country: any) => country.countryId === countryId,
      );
      if (findMatchingCountryMetadata) {
        nameEn = findMatchingCountryMetadata.nameShortEn;
      } else {
        nameEn = "undisclosed";
      }

      return {
        ...d,
        id: d.countryId,
        topLevelParent,
        nameEn,
        hybrid_level_1,
        hybrid_level_2,
      };
    });

    let groupedByLocationDetailLevel;
    if (currentLocationDetailLevel === LocationDetailLevel.country) {
      groupedByLocationDetailLevel = dataWithParents;
    } else if (currentLocationDetailLevel === LocationDetailLevel.subregion) {
      groupedByLocationDetailLevel = groups(
        dataWithParents,
        (d: any) => d.hybrid_level_2.id,
      ).map((d: any) => {
        const groupId = d[0];
        const locationLevel = d[1][0].locationLevel;
        const sumExportValue = sum(d[1], (v: any) => v.exportValue);
        const sumImportValue = sum(d[1], (v: any) => v.importValue);
        return {
          id: groupId,
          locationLevel: locationLevel,
          year: year,
          exportValue: sumExportValue,
          importValue: sumImportValue,
          productsData: d[1],
          topLevelParent: d[1][0].topLevelParent,
          hybrid_level_1: d[1][0].hybrid_level_1,
          hybrid_level_2: d[1][0].hybrid_level_2,
        };
      });
    } else if (currentLocationDetailLevel === LocationDetailLevel.region) {
      groupedByLocationDetailLevel = groups(
        dataWithParents,
        (d: any) => d.hybrid_level_1.id,
      ).map((d: any) => {
        const groupId = d[0];
        const locationLevel = d[1][0].locationLevel;
        const sumExportValue = sum(d[1], (v: any) => v.exportValue);
        const sumImportValue = sum(d[1], (v: any) => v.importValue);
        return {
          locationLevel: locationLevel,
          id: groupId,
          year: year,
          exportValue: sumExportValue,
          importValue: sumImportValue,
          productsData: d[1],
          topLevelParent: d[1][0].topLevelParent,
          hybrid_level_1: d[1][0].hybrid_level_1,
          hybrid_level_2: d[1][0].hybrid_level_2,
        };
      });
    } else {
      // This will never execute
      groupedByLocationDetailLevel = dataWithParents;
    }

    let topLevelParentsObjects = [...topLevelParents.values()].map((t) => ({
      id: t,
      topLevelParent: "root",
    }));
    topLevelParentsObjects.push({ id: "undisclosed", topLevelParent: "root" });

    const rootObject = { id: "root", topLevelParent: undefined };
    return [
      rootObject,
      ...topLevelParentsObjects,
      ...groupedByLocationDetailLevel,
    ];
  }
};

export default transformLocations;
