import { gql } from "@apollo/client";

// TO DO: use automatic way to generate world object from API, not manual
export const worldGroupDatum = {
  groupId: "group-1",
  groupName: "World",
  groupType: "world",
};

const query = gql`
  query GetLocations {
    countries: locationCountry {
      countryId
      locationLevel
      iso3Code
      nameEn
      nameShortEn
      legacyCountryId
      inCp
      inMv
      isTrusted
      reportedServ
      reportedServRecent
      thePrefix
    }

    regions: locationGroup(groupType: region) {
      groupId
      groupName
      groupType
      members
      parentId
    }

    subregions: locationGroup(groupType: subregion) {
      groupId
      groupName
      groupType
      members
      parentId
    }
  }
`;

export default query;
